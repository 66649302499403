import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './App.scss'
import './components/index.scss'
import { Provider } from 'react-redux';
import FallbackUI from "./components/shared/fallback-ui/fallback-page.component";
import store from './stores/app-store';
import ToasterContainerComponent from './components/shared/toaster/toaster-container.component';

//*****do not remove commented PostHog code********/

// import { PostHogProvider } from 'posthog-js/react'
// import Config from './config';

const TeamsApp = lazy(() => import('./TeamsApp'));
const App = lazy(() => import('./App'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const options = {
//   api_host: Config.POSTHOG_HOST,
// }

root.render(
  // <React.StrictMode>
  <Suspense fallback={<FallbackUI />}>
    {/* <PostHogProvider apiKey={Config.POSTHOG_KEY} options={options}> */}
    <Provider store={store}>
      {window.location.pathname.includes("/teams") ? <TeamsApp /> : <App />}
      <ToasterContainerComponent />
    </Provider>
    {/* </PostHogProvider> */}
  </Suspense>
  //  </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
