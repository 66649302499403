import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, current } from '@reduxjs/toolkit'
import { AlertNotificationModel } from '../models/alert.model'
import { AutoAlertKeywordModel, RuleModel } from '../models/auto-alert.model'
import {
    Contact, ContactType, ConversationModel, DeletedGroupContactData, Group, GroupChatModel, OutLookResponse,
    pushConversation,
    pushConversationInContact,
    pushHistory,
    sortContacts,
    sortConversations,
    TypeAction,
    TypingDataModel, updateContactsInConversations, updateContactsInGroup, updateContactTypesInConversations, updateGroupsInContacts
} from '../models/conversations'
import { FeaturesModel } from '../models/features.model'
import { KafkaModel } from '../models/kafka.model'
import { OrganizationModel } from '../models/organization.model'
import { PhoneNumberModel, ProviderModel } from '../models/phone-number.model'
import { mapToPlanMetrics, PlanMetrics } from '../models/plan-metrics.model'
import { PlanModel } from '../models/plans.model'
import { ProfileModel } from '../models/profile.model'
import { ReportsModel } from '../models/reports.model'
import { RoleModel } from '../models/roles.model'
import { Setting, SettingsModel } from '../models/settings-model'
import { TemplateModel } from '../models/templates.models'
import { PlanHistoryModel } from '../models/transaction-history.model'
import { UserModel } from '../models/users.model'
import { SettingsService } from '../services/SettingsService'
import { UserService } from '../services/UserService'
import { AppState } from './models'

const initialState: AppState = {
    userRoleId: UserService.getLoggedInUserRole() ?? 0,
    Conversations: [],
    Organizations: [],
    OrganizationDetails: [],
    History: {},
    ContactTypes: [],
    Contacts: [],
    AllContacts: [],
    Users: [],
    AllUsers: [],
    Groups: [],
    Features: [],
    Settings: {
        BlockedContacts: [],
        UnSubscribedContacts: [],
        AllowedWrongPasswordAttempts: 0,
        NotificationsEnabled: false,
        TwoFactAuthEnabled: false,
        Settings: [],
        VoiceVerificationEnabled: true,
    } as SettingsModel,
    Profile: {
        FullName: 'U K',
        Email: '',
        FirstName: '',
        ImageReferenceId: '',
        LastName: '',
        PhoneNumber: '',
        OrganizationName: '',
        OrgId: JSON.parse(localStorage.getItem("current-user")?.toString() || "{}")
            ?.organizationId || '',
        RoleID: '',
    } as ProfileModel,
    IsMobileDevice: window.outerWidth <= 835,
    ShowSideMenu: false,
    IsTeamsApp: window.location.pathname.includes('teams'),
    DeviceWidth: window.outerWidth,
    DeviceHeight: window.innerHeight,
    PhoneNumbers: [],
    Roles: [],
    Plans: [],
    Providers: [],
    AlertNotifications: [],
    UserAlertNotifications: [],
    RulesKeywords: [],
    Templates: [],
    AllKeywords: [],
    PrivateRules: [],
    AllPrivateRules: [],
    GlobalRules: [],
    AllGlobalRules: [],
    Reports: [],
    PlanHistory: [],
    GetBlockedContacts: [],
    GetUnSubscribedContacts: [],
    GroupContactsDeletionData: [],
    TypingData: [],
    SecureFiles: [],
    FaceAuthenticationFiles: [],
    SecureFileLinks: [],
    LoadingConversations: false,
    LoadingHistory: false,
    LoadingContacts: false,
    LoadingContactTypes: false,
    LoadingUsers: false,
    LoadingGroups: false,
    LoadingOrganizations: false,
    LoadingSettings: false,
    LoadingProfile: false,
    LoadingFeatures: false,
    LoadingRoles: false,
    LoadingPhoneNumbers: false,
    LoadingPlans: false,
    LoadingProviders: false,
    LoadingOrganizationDetails: false,
    LoadingAlertsNotications: false,
    LoadingAutoAlertKeywords: false,
    LoadingTemplates: false,
    LoadedConversations: false,
    LoadedHistory: false,
    LoadedContacts: false,
    LoadedContactTypes: false,
    LoadedUsers: false,
    LoadedGroups: false,
    LoadedOrganizations: false,
    LoadedSettings: false,
    LoadedProfile: false,
    LoadedFeatures: false,
    LoadedRoles: false,
    LoadedPhoneNumbers: false,
    LoadedPlans: false,
    LoadedProviders: false,
    LoadedOrganizationDetails: false,
    LoadingAutomationPrivateRules: false,
    LoadingAutomationGlobalRules: false,
    LoadingReports: false,
    LoadingKafka: false,
    Kafka: [],
    LoadingSecureFiles: false,
    LoadingSecureFileLinks: false,
    IsMassContactsDeletionInProgress: false,
    IsMassGroupsDeletionInProgress: false,
    HandleMassContactsActionEvent: false,
    HandleMassGroupsActionEvent: false,
    SyncingContacts: {
        flag: false,
        existingContacts: 0,
        ignoredContacts: 0,
        savedContacts: 0,
        totalContacts: 0,
        error: "",
        loadContacts: false
    },
    NewMessageRecived: [],
    PlanMetrics: mapToPlanMetrics(
        JSON.parse(localStorage.getItem("current-user")?.toString() || "{}").planTracker
    ),
    ShowingAlertsCount: 0,
    Toasts: [],
    ExportMessageLogs: [],
    IsMessageLogDownloadReportMsg: '',
    WebNotificationText: {},
    OrganizationName: "",
    LoadingscheduleMessages: false,
    ScheduleMessages: [],
    DataLoading: false,
    PhonenumberSelectedOrgIDs: [],
    UsersSelectedOrgIDs: [],
    OrganizationsphoneNumbers: [],
    OrganizationsUsers: [],
    ActivityFeedNotificationStatus: {
        integrationStatus: false,
        message: "",
        notificationStatus: false
    },
    LoadingFaceAuthenticationFiles: false,
}

const counterSlice = createSlice({
    name: 'appReducer',
    initialState,
    reducers: {
        updateUserRoleId: (state: AppState, action: PayloadAction<number>) => {
            state.userRoleId = action.payload
        },

        updateConversations: (state: AppState, action: PayloadAction<ConversationModel[]>) => {
            state.Conversations = updateContactTypesInConversations(
                updateContactsInConversations(action.payload, state.Contacts),
                state.ContactTypes
            );
            state.LoadingConversations = false;
            state.LoadedConversations = true;
        },

        updateMuteConversations: (state: AppState, action: PayloadAction<any[]>) => {
            state.Conversations.forEach((conv) => {
                const found = action.payload.some(el => el.phoneNumber === conv.PhoneNumber);
                conv.IsMuted = found
            })
        },

        updateArchiveConversations: (state: AppState, action: PayloadAction<any[]>) => {
            state.Conversations.forEach((conv) => {
                const found = action.payload.some(el => el.phoneNumber === conv.PhoneNumber && el.isArchived);
                conv.IsArchived = found
            })
        },

        updateLoadingConversations: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingConversations = action.payload;
        },

        updateDataLoading: (state: AppState, action: PayloadAction<boolean>) => {
            state.DataLoading = action.payload;
        },

        updateHistory: (state: AppState, action: PayloadAction<{ phoneNumber: string, history: ConversationModel[] }>) => {
            state.History = {
                ...state.History,
                [action.payload.phoneNumber]: action.payload.history
            }
        },

        clearHistory: (state: AppState, action: PayloadAction<{ [key: string]: ConversationModel[] }>) => {
            state.History = {}
        },

        cleanHistory: (state: AppState, action: PayloadAction<string>) => {
            const phoneNumber = (action.payload || '').replace('+', '')

            let history = state.History[phoneNumber]?.filter((x) => x.ID) || []
            if (history.length > 0) {
                let oldCon = state.Conversations.find(
                    (x) => x.PhoneNumber === action.payload
                )
                let newCon = history[history.length - 1]
                if (oldCon && newCon) {
                    newCon.Contact = oldCon.Contact
                }
                let updatedConversations =
                    history.length > 0
                        ? [
                            ...state.Conversations?.filter(
                                (x) => x.PhoneNumber !== action.payload
                            ),
                            newCon,
                        ]
                        : []
                state.Conversations = sortConversations(updatedConversations);
                state.History = {
                    ...state.History,
                    [phoneNumber]: history,
                };
            }
        },

        updateContacts: (state: AppState, action: PayloadAction<Contact[]>) => {
            let contactData = action.payload.filter((x: Contact, index: number) => action.payload.findIndex((y: Contact) => y.ID === x.ID) === index).filter((x: Contact) => !(x.IsDeleted || !x.FirstName));
            state.AllContacts = action.payload;
            state.Contacts = sortContacts(updateGroupsInContacts(contactData, state.Groups));
            state.Groups = updateContactsInGroup(state.Groups, action.payload);
            state.Settings.BlockedContacts = action.payload?.filter((x: Contact) => x.IsBlocked);
            state.Settings.UnSubscribedContacts = action.payload?.filter(
                (x: Contact) => x.IsUnsubscribed
            );
            state.LoadingContacts = false;
            state.LoadedContacts = true;
            state.IsMassContactsDeletionInProgress = false;
        },

        updateLoadingContacts: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingContacts = action.payload
        },

        updateContactTypes: (state: AppState, action: PayloadAction<ContactType[]>) => {
            state.ContactTypes = action.payload
        },

        updateContactsInConversation: (state: AppState, action: PayloadAction<Contact[]>) => {
            state.Conversations = updateContactsInConversations(
                state.Conversations,
                state.Contacts
            );
        },

        updateContactTypesInConversation: (state: AppState, action: PayloadAction<ContactType[]>) => {
            state.Conversations = updateContactTypesInConversations(
                state.Conversations,
                action.payload
            );
        },

        pushConversations: (state: AppState, action: PayloadAction<ConversationModel[]>) => {
            state.Conversations = pushConversation(
                state.Conversations,
                state.Contacts,
                action.payload
            );
            state.Contacts = pushConversationInContact(state.Contacts, action.payload);
            state.History = pushHistory(state.History, action.payload);
            state.NewMessageRecived = action.payload.filter(x => !x.IsOutbound)
        },

        updateNewMessageRecived: (state, action) => {
            state.NewMessageRecived = action.payload
        },

        pushContacts: (state: AppState, action: PayloadAction<Contact[]>) => {
            // let { Conversations, Contacts } = state;
            // action.payload?.forEach((contact: Contact) => {
            //     let _conversation = Conversations?.find(x => x.PhoneNumber === contact.PhoneNumber || x.Contact?.ID === contact.ID);
            //     let _contact = Contacts?.find(x => x.ID === contact.ID);
            //     if (_conversation) {
            //         _conversation.UnReadCount = contact.UnReadCount;
            //         _conversation.IsBlocked = contact.IsBlocked;
            //     }

            //     if (_conversation?.Contact?.ID === contact.ID && (contact.IsDeleted || contact.MaskedPhoneNumber !== _conversation?.Contact?.MaskedPhoneNumber)) {
            //         _conversation.Contact = undefined;
            //     }

            //     if (_contact) {
            //         if (_contact.IsBlocked !== contact.IsBlocked) {
            //             _contact.IsBlocked = contact.IsBlocked;
            //             _contact.CreatedDateTime = contact.CreatedDateTime;
            //         }
            //         _contact.UnReadCount = contact.UnReadCount;
            //         _contact.IsDeleted = contact.IsDeleted;
            //     }
            // });
            // if (!!state.Contacts?.filter((x) => action.payload.map(y => y.ID)?.includes(x.ID))) {
            //     state.Contacts = sortContacts([
            //         ...state.Contacts?.filter((x) => !action.payload.map(y => y.ID)?.includes(x.ID)),
            //         ...action.payload,
            //     ]?.filter((x: Contact) => !(x.IsDeleted || !x.FirstName)));
            //     state.AllContacts = [
            //         ...state.AllContacts?.filter((x) => !action.payload.map(y => y.ID)?.includes(x.ID)),
            //         ...action.payload,
            //     ]
            // }
            // else {
            //     state.Contacts = sortContacts([...Contacts, ...action.payload?.filter((x: Contact) => !Contacts?.map(y => y.ID)?.includes(x.ID))]?.filter((x: Contact) => !(x.IsDeleted || !x.FirstName)))
            // }

            // // const contacts = [...Contacts, ...action.payload?.filter((x: Contact) => !Contacts?.map(y => y.ID)?.includes(x.ID))]?.filter((x: Contact) => !(x.IsDeleted || !x.FirstName))
            // state.Conversations = [...Conversations];
            // // state.Contacts = sortContacts([...contacts,]);
            // state.Groups = updateContactsInGroup(state.Groups, state.Contacts);
            // state.Settings = {
            //     ...state.Settings,
            //     BlockedContacts: Contacts?.filter((x: Contact) => x.IsBlocked),
            //     UnSubscribedContacts: Contacts?.filter((x: Contact) => x.IsUnsubscribed)
            // }

            let { Conversations, Contacts } = state;
            action.payload?.forEach((contact: Contact) => {
                let _conversation = Conversations?.find(x => x.PhoneNumber === contact.PhoneNumber || x.Contact?.ID === contact.ID);
                let _contact = Contacts?.find(x => x.ID === contact.ID);
                if (_conversation) {
                    _conversation.UnReadCount = contact.UnReadCount;
                    _conversation.IsBlocked = contact.IsBlocked;
                }

                if (_conversation?.Contact?.ID === contact.ID && (contact.IsDeleted || contact.MaskedPhoneNumber !== _conversation?.Contact?.MaskedPhoneNumber)) {
                    _conversation.Contact = undefined;
                }

                if (_contact) {
                    if (_contact.IsBlocked !== contact.IsBlocked) {
                        _contact.IsBlocked = contact.IsBlocked;
                        _contact.CreatedDateTime = contact.CreatedDateTime;
                    }
                    _contact.UnReadCount = contact.UnReadCount;
                    _contact.IsDeleted = contact.IsDeleted;
                }
            });
            const filteredPayload = action.payload.map(y => y.ID);

            if (Contacts?.some((x) => filteredPayload.includes(x.ID))) {
                state.Contacts = sortContacts([
                    ...state.Contacts?.filter((x) => !filteredPayload?.includes(x.ID)),
                    ...action.payload,
                ]?.filter((x: Contact) => !(x.IsDeleted || !x.FirstName)));
                state.AllContacts = [
                    ...state.AllContacts?.filter((x) => !filteredPayload?.includes(x.ID)),
                    ...action.payload,
                ]
            }
            else {
                state.Contacts = sortContacts([...Contacts, ...action.payload?.filter((x: Contact) => !Contacts?.map(y => y.ID)?.includes(x.ID))]?.filter((x: Contact) => !(x.IsDeleted || !x.FirstName)))
                state.AllContacts = [
                    ...state.AllContacts?.filter((x) => !filteredPayload?.includes(x.ID)),
                    ...action.payload,
                ]
            }

            // const contacts = [...Contacts, ...action.payload?.filter((x: Contact) => !Contacts?.map(y => y.ID)?.includes(x.ID))]?.filter((x: Contact) => !(x.IsDeleted || !x.FirstName))
            state.Conversations = [...Conversations];
            // state.Contacts = sortContacts([...contacts,]);
            state.Groups = updateContactsInGroup(state.Groups, state.Contacts);

            const contactsSet = new Set(Contacts);
            const blockedList = []
            const unsubscribeList = []
            contactsSet?.forEach((c) => {
                if (c.IsBlocked) {
                    blockedList.push(c)
                }
                if (c.IsUnsubscribed) {
                    unsubscribeList.push(c)
                }
            })

            state.Settings = {
                ...state.Settings,
                BlockedContacts: blockedList,
                UnSubscribedContacts: unsubscribeList
            }
        },

        pushGroupChat: (state: AppState, action: PayloadAction<GroupChatModel[]>) => {
            let { Conversations } = state;
            action.payload?.forEach((x: GroupChatModel) => {
                let con = Conversations?.find(y => y.GroupChat?.ID === x.ID)
                if (con) {
                    con.GroupChat.UnReadCount = x.UnReadCount;
                }
            });
            state.Conversations = [...Conversations];
        },

        updateGroups: (state: AppState, action: PayloadAction<Group[]>) => {
            state.Groups = updateContactsInGroup(action.payload, state.Contacts);
            state.Contacts = updateGroupsInContacts(state.Contacts, action.payload);
            state.LoadingGroups = false;
            state.LoadedGroups = true;
            state.IsMassGroupsDeletionInProgress = false;
        },

        updateLoadingGroups: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingGroups = action.payload
        },

        pushGroups: (state: AppState, action: PayloadAction<Group[]>) => {
            let groups = [...state.Groups?.filter((x) => !action.payload?.map((y: Group) => y.ID)?.includes(x.ID)), ...action.payload]?.filter((x: Group) => !x.IsDeleted)
            state.Groups = updateContactsInGroup(groups, state.Contacts);
            state.Contacts = updateGroupsInContacts(state.Contacts, action.payload)
        },

        updateUnsubscribedContacts: (state: AppState, action: PayloadAction<Contact[]>) => {
            state.GetUnSubscribedContacts = action.payload;
        },

        updateBlockedContacts: (state: AppState, action: PayloadAction<Contact[]>) => {
            state.GetBlockedContacts = action.payload;
        },

        // updateProfile: (state: AppState, action: PayloadAction<ProfileModel>) => {
        //     state.Profile = { ...action.payload, OrganizationName: state.Profile?.OrganizationName };
        //     state.LoadingProfile = false;
        //     state.LoadedProfile = true;
        // },

        updatePlanMetrics: (state: AppState, action: PayloadAction<PlanMetrics>) => {
            state.PlanMetrics = { ...action.payload } as PlanMetrics;
        },

        // updateLoadingProfile: (state: AppState, action: PayloadAction<boolean>) => {
        //     state.LoadingProfile = action.payload;
        // },

        updateOrgName: (state: AppState, action: PayloadAction<ProfileModel>) => {
            // state.Profile = { ...state.Profile, OrganizationName: action.payload as any} as ProfileModel;
            state.OrganizationName = action.payload as any;
        },

        updateContact: (state: AppState, action: PayloadAction<Contact>) => {
            // state.Contacts = sortContacts([
            //     ...state.Contacts?.filter((x) => x.ID !== action.payload.ID),
            //     action.payload,
            // ]);
            state.Groups = updateContactsInGroup(state.Groups, [
                ...state.Contacts?.filter((x) => x.ID !== action.payload.ID),
                action.payload,
            ]);
            state.Conversations = sortConversations(
                [
                    ...state.Conversations?.filter(
                        (x) => x.PhoneNumber !== action.payload?.PhoneNumber
                    ),
                    {
                        ...state.Conversations?.find(
                            (x) => x.PhoneNumber === action.payload?.PhoneNumber
                        ),
                        Contact: { ...action.payload },
                    } as ConversationModel,
                ]?.filter((x) => x.PhoneNumber)
            )
        },

        updateConversation: (state: AppState, action: PayloadAction<ConversationModel>) => {
            state.Conversations = sortConversations([
                ...state.Conversations?.filter(
                    (x) => x.Campaign?.ID ? x.Campaign.ID !== action.payload?.PhoneNumber : x.GroupChat?.ID ? x.GroupChat.ID !== action.payload?.PhoneNumber : x.PhoneNumber !== action.payload?.PhoneNumber
                ),
                action.payload
            ])
        },

        updateSettings: (state: AppState, action) => {
            state.Settings = {
                ...state.Settings,
                Settings: action.payload,
                AllowedWrongPasswordAttempts: parseInt(
                    action.payload?.find(
                        (x: Setting) =>
                            x.Code === SettingsService.Wrong_Password_Attmps_Code
                    )?.Value || state.Settings?.AllowedWrongPasswordAttempts
                ),
                NotificationsEnabled: action.payload?.find(
                    (x: Setting) => x.Code === SettingsService.Notification_Code
                )
                    ? action.payload
                        ?.find(
                            (x: Setting) => x.Code === SettingsService.Notification_Code
                        )
                        .Value?.toLowerCase() === 'true'
                    : state.Settings.NotificationsEnabled,
                TwoFactAuthEnabled: action.payload?.find(
                    (x: Setting) => x.Code === SettingsService.Two_FactAuth_Code
                )
                    ? action.payload
                        ?.find(
                            (x: Setting) => x.Code === SettingsService.Two_FactAuth_Code
                        )
                        ?.Value.toLowerCase() === 'true'
                    : state.Settings.TwoFactAuthEnabled,
                VoiceVerificationEnabled: action.payload?.find(
                    (x: Setting) => x.Code === SettingsService.Voice_Otp_Veification
                )
                    ? action.payload
                        ?.find(
                            (x: Setting) =>
                                x.Code === SettingsService.Voice_Otp_Veification
                        )
                        .Value?.toLowerCase() === 'true'
                    : state.Settings?.VoiceVerificationEnabled,
            }

            let setTimeZone = action.payload?.find(x => x.Code === SettingsService.Auto_Time_Zone_Code)?.Value?.toString()?.toLowerCase() === "false" ? action.payload?.find(x => x.Code === SettingsService.Time_Zone_Code)?.Value?.toString() : ""
            localStorage.setItem("Timezone", setTimeZone)
        },

        updateMenuFlag: (state: AppState, action: PayloadAction<boolean>) => {
            state.ShowSideMenu = action.payload
        },

        updateMobileDevice: (state: AppState) => {
            let width = window.outerWidth < 320 ? window.innerWidth : window.outerWidth
            state.IsMobileDevice = width <= 835 && width !== 0;
            state.DeviceWidth =
                width < 320
                    ? state.DeviceWidth < 320
                        ? 320
                        : state.DeviceWidth
                    : width;
            state.DeviceHeight = window.innerHeight
        },

        updateFeatures: (state: AppState, action: PayloadAction<FeaturesModel[]>) => {
            state.Features = action.payload;
            state.LoadingFeatures = false;
            state.LoadedFeatures = true;
        },

        updateLoadingFeatures: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingFeatures = action.payload
        },

        updateOrganizations: (state: AppState, action: PayloadAction<OrganizationModel[]>) => {
            state.Organizations = action.payload;
            state.LoadingOrganizations = false;
            state.LoadedOrganizations = true;
        },

        updateLoadingOrganizations: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingOrganizations = action.payload
        },

        updatePhoneNumber: (state: AppState, action: PayloadAction<PhoneNumberModel>) => {
            state.PhoneNumbers = [
                action.payload,
                ...state.PhoneNumbers?.filter((x) => x.ID !== action.payload.ID),
            ];
            state.LoadingPhoneNumbers = false;
            state.LoadedPhoneNumbers = true;
        },

        updatePhoneNumbers: (state: AppState, action: PayloadAction<PhoneNumberModel[]>) => {
            state.PhoneNumbers = action.payload;
            state.LoadingPhoneNumbers = false;
            state.LoadedPhoneNumbers = true;
        },

        updatePhoneNumbersGridData: (state: AppState, action: PayloadAction<PhoneNumberModel[]>) => {
            state.PhoneNumbers = action.payload;
            state.LoadingPhoneNumbers = false;
            state.LoadedPhoneNumbers = true;
        },


        updateLoadingPhoneNumbers: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingPhoneNumbers = action.payload;
        },

        updateRoles: (state: AppState, action: PayloadAction<RoleModel[]>) => {
            state.Roles = action.payload;
        },

        updatePlans: (state: AppState, action: PayloadAction<PlanModel[]>) => {
            state.Plans = action.payload;
            state.LoadingPlans = false;
            state.LoadedPlans = true;
        },

        updateLoadingPlanes: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingPlans = action.payload
        },

        updateProviders: (state: AppState, action: PayloadAction<ProviderModel[]>) => {
            state.Providers = action.payload;
            state.LoadingProviders = false;
            state.LoadedProviders = true;
        },
        updateLoadingProviders: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingProviders = action.payload
        },

        headerTitle: (state: AppState, action) => {
            state.HeaderTitle = action.payload;
            state.HeaderCount = undefined;
            state.RefreshHandler = undefined;
            state.BackHandler = undefined
        },

        headerCount: (state: AppState, action: PayloadAction<number>) => {
            state.HeaderCount = action.payload
        },

        refreshHandler: (state: AppState, action) => {
            state.RefreshHandler = action.payload
        },

        backHandler: (state: AppState, action) => {
            state.BackHandler = action.payload;
        },

        updateAlertNotification: (state: AppState, action: PayloadAction<AlertNotificationModel[]>) => {
            state.AlertNotifications = action.payload;
            state.LoadingAlertsNotications = false;
        },

        updateLoadingAlertNotification: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingAlertsNotications = action.payload
        },

        updateUserAlertNotifaction: (state: AppState, action: PayloadAction<AlertNotificationModel[]>) => {
            state.UserAlertNotifications = action.payload
        },

        setShowingAlert: (state: AppState, action: PayloadAction<number>) => {
            state.ShowingAlertsCount = action.payload
        },

        updateLoadingKeywords: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingAutoAlertKeywords = action.payload;
        },

        updateKeywords: (state: AppState, action: PayloadAction<AutoAlertKeywordModel[]>) => {
            state.RulesKeywords = action.payload?.filter(
                (x: AutoAlertKeywordModel) => !x.IsDeleted
            );
            state.AllKeywords = action.payload;
            state.LoadingAutoAlertKeywords = false;
        },

        updateTemplates: (state: AppState, action: PayloadAction<{ data: any, refresh: boolean }>) => {
            let { Templates } = state;
            let templatesData = action.payload.refresh ? action.payload.data : current(Templates).concat(action.payload.data)
            state.Templates = templatesData.sort((x: TemplateModel, y: TemplateModel) => new Date(x.UpdatedDateTime) <= new Date(y.UpdatedDateTime) ? 1 : -1);
            state.LoadingTemplates = false;
        },

        updateLoadingTemplates: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingTemplates = action.payload;
        },

        updateUsers: (state: AppState, action: PayloadAction<UserModel[]>) => {
            let regex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            state.Users = action.payload?.filter((x: UserModel) =>
                regex.test(String(x.Email).toLowerCase())
            );
            state.AllUsers = action.payload;
            state.LoadingUsers = false;
            state.LoadedUsers = true;
        },

        updateLoadingUsers: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingUsers = false
        },

        updateLoadingPrivateAutomationRules: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingAutomationPrivateRules = action.payload
        },

        updateLoadingGlobalAutomationRules: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingAutomationGlobalRules = action.payload;
        },

        updatePrivateAutomationRules: (state: AppState, action: PayloadAction<RuleModel[]>) => {
            state.PrivateRules = action.payload?.filter((x: RuleModel) => !x.IsDeleted);
            state.AllPrivateRules = action.payload;
            state.LoadingAutomationPrivateRules = false;
        },

        updateGlobalAutomationRules: (state: AppState, action: PayloadAction<RuleModel[]>) => {
            state.GlobalRules = action.payload?.filter((x: RuleModel) => !x.IsDeleted);
            state.AllGlobalRules = action.payload;
            state.LoadingAutomationGlobalRules = false;
        },

        updateReports: (state: AppState, action: PayloadAction<ReportsModel[]>) => {
            state.Reports = action.payload;
            state.LoadingReports = false;
        },

        loadingReports: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingReports = action.payload
        },

        updatePlanHistory: (state: AppState, action: PayloadAction<PlanHistoryModel[]>) => {
            state.PlanHistory = action.payload
        },

        setKafka: (state: AppState, action: PayloadAction<KafkaModel[]>) => {
            state.Kafka = action.payload?.sort((a: KafkaModel, b: KafkaModel) => a.GroupId > b.GroupId ? 1 : -1)
        },

        updateKafka: (state: AppState, action: PayloadAction<KafkaModel>) => {
            state.Kafka = [...state.Kafka?.filter(x => x.GroupId !== action.payload?.GroupId), action.payload]
                .sort((a: KafkaModel, b: KafkaModel) => a.GroupId > b.GroupId ? 1 : -1)
        },

        loadingKafkaOrg: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingKafka = action.payload
        },

        syncingContacts: (state: AppState, action: PayloadAction<OutLookResponse>) => {
            state.SyncingContacts = action.payload
        },

        groupContactsDeletion: (state: AppState, action: PayloadAction<DeletedGroupContactData[]>) => {
            state.GroupContactsDeletionData = action.payload
        },

        updateTypingData: (state: AppState, action: PayloadAction<TypingDataModel>) => {
            const data = action.payload as TypingDataModel;
            state.TypingData = data.Action === TypeAction.START_TYPING ? [...state.TypingData.filter(x => x.UserID !== data.UserID), data] : state.TypingData.filter(x => x.ReceiverPhoneNumber !== data.ReceiverPhoneNumber || x.UserID !== data.UserID)
        },

        updateMassContactsDeletionInProgess: (state, action) => {
            state.IsMassContactsDeletionInProgress = action.payload
        },

        handleMassContactsActionEvent: (state, action) => {
            state.HandleMassContactsActionEvent = action.payload
        },

        updateMassGroupsDeletionInProgess: (state, action) => {
            state.IsMassGroupsDeletionInProgress = action.payload
        },

        handleMassGroupsActionEvent: (state, action) => {
            state.HandleMassGroupsActionEvent = action.payload
        },

        loadingSecureFiles: (state, action) => {
            state.LoadingSecureFiles = action.payload
        },

        loadingFaceAuthenticationFiles: (state, action) => {
            state.LoadingFaceAuthenticationFiles = action.payload
        },

        updateFaceAuthenticationFiles: (state, action) => {
            state.LoadingFaceAuthenticationFiles = false;
            if (Array.isArray(action.payload)) {
                state.FaceAuthenticationFiles = action.payload;
            } else {
                if (!!state.FaceAuthenticationFiles?.find(x => x.ContactPhoneNumber === action.payload?.ContactPhoneNumber)) {
                    state.FaceAuthenticationFiles = state.FaceAuthenticationFiles?.map(x => x.ContactPhoneNumber === action.payload?.ContactPhoneNumber ? action.payload : x)
                }
                else {
                    state.FaceAuthenticationFiles = [...state.FaceAuthenticationFiles, action.payload]
                }
            }
        },

        updateSecureFiles: (state, action) => {
            state.SecureFiles = action.payload;
            state.LoadingSecureFiles = false;
        },

        loadingSecureFilesLinks: (state, action) => {
            state.LoadingSecureFileLinks = action.payload
        },

        updateSecureFilesLinks: (state, action) => {
            state.SecureFileLinks = action.payload;
            state.LoadingSecureFileLinks = false;
        },
        handlePushGroupChatName: (state, action) => {
            let { Conversations } = state;
            let con = Conversations.find(y => y.GroupChat?.ID === action.payload.id)
            if (con) {
                con.GroupChat.Name = action.payload.name;
            }
            state.Conversations = Conversations
        },
        updateActivityFeedNotificationStatus: (state, action) => {
            state.ActivityFeedNotificationStatus = action.payload
        },
        handlePushCampaignChatName: (state, action) => {
            let { Conversations } = state;
            let con = Conversations.find(y => y.Campaign?.ID === action.payload.id)
            if (con) {
                con.Campaign.Name = action.payload?.name;
            }
            state.Conversations = Conversations
        },
        loadingscheduleMessages: (state: AppState, action: PayloadAction<boolean>) => {
            state.LoadingscheduleMessages = action.payload
        },
        updateScheduleMessages: (state, action) => {
            state.ScheduleMessages = action.payload
            state.LoadingscheduleMessages = false
        },
        updatePhonenumberSelectedOrgIDs: (state: AppState, action) => {
            state.PhonenumberSelectedOrgIDs = action.payload
        },
        updateOrganizationsphoneNumbers: (state, action) => {
            state.OrganizationsphoneNumbers = action.payload
        },
        updateUsersSelectedOrgIDs: (state: AppState, action) => {
            state.UsersSelectedOrgIDs = action.payload
        },

        updateOrganizationsUsers: (state, action) => {
            state.OrganizationsUsers = action.payload
        },



        //toaster functions
        setToasts: (state, action) => {
            state.Toasts = [
                {
                    ID: action.payload.id,
                    Text: action.payload.text,
                    Timeout: action.payload.timeout,
                    Type: action.payload.type,
                    Hide: true,
                },
                ...state.Toasts
            ]
        },
        showToast: (state, action) => {
            let x = state.Toasts.find((x) => x.ID === action.payload.id)
            if (x) {
                x.Hide = false
            }

        },
        hideToast: (state, action) => {
            let x = state.Toasts.find((x) => x.ID === action.payload.id)
            if (x) {
                x.Hide = true
            }
        },
        clearToast: (state, action) => {
            state.Toasts = state.Toasts.filter((x) => x.ID !== action.payload.id)
        },
        LoadMsgLogsExports: (state, action) => {
            state.ExportMessageLogs = action.payload
        },
        pushMessageLogsDownloadReport: (state, action) => {
            state.IsMessageLogDownloadReportMsg = action.payload
        },
        HandleWebNotication: (state, action) => {
            state.WebNotificationText = action.payload
        },

    },
})

export const {
    updateUserRoleId,
    updateConversations,
    updateMuteConversations,
    updateArchiveConversations,
    updateContactTypes,
    updateContactTypesInConversation,
    updateContacts,
    updateContactsInConversation,
    updateConversation,
    updateFeatures,
    updateGlobalAutomationRules,
    updateLoadingConversations,
    updateGroups,
    updatePhoneNumber,
    updatePlanHistory,
    updatePlanMetrics,
    updatePlans,
    updatePrivateAutomationRules,
    // updateProfile,
    updateProviders,
    updateReports,
    updateRoles,
    updateSettings,
    updateUnsubscribedContacts,
    updateUserAlertNotifaction,
    updateHistory,
    updateKeywords,
    updateLoadingAlertNotification,
    updateLoadingContacts,
    updateLoadingFeatures,
    updateLoadingGlobalAutomationRules,
    updateLoadingGroups,
    updateLoadingKeywords,
    updateLoadingOrganizations,
    updateLoadingPhoneNumbers,
    updateLoadingPlanes,
    updateLoadingPrivateAutomationRules,
    // updateLoadingProfile,
    updateLoadingProviders,
    updateMobileDevice,
    updateOrgName,
    updateOrganizations,
    updatePhoneNumbers,
    updateTemplates,
    updateLoadingTemplates,
    updateMenuFlag,
    updateUsers,
    updateLoadingUsers,
    clearHistory,
    loadingReports,
    pushContacts,
    pushConversations,
    backHandler,
    headerCount,
    headerTitle,
    refreshHandler,
    setShowingAlert,
    updateAlertNotification,
    updateBlockedContacts,
    updateContact,
    loadingKafkaOrg,
    setKafka,
    updateKafka,
    cleanHistory,
    pushGroups,
    pushGroupChat,
    syncingContacts,
    groupContactsDeletion,
    updateTypingData,
    updateMassContactsDeletionInProgess,
    updateMassGroupsDeletionInProgess,
    loadingSecureFiles,
    loadingFaceAuthenticationFiles,
    updateSecureFiles,
    loadingSecureFilesLinks,
    updateSecureFilesLinks,
    updateNewMessageRecived,
    pushMessageLogsDownloadReport,
    handlePushGroupChatName,
    handlePushCampaignChatName,
    clearToast,
    hideToast,
    setToasts,
    showToast,
    LoadMsgLogsExports,
    HandleWebNotication,
    updatePhoneNumbersGridData,
    updateScheduleMessages,
    loadingscheduleMessages,
    updateDataLoading,
    updatePhonenumberSelectedOrgIDs,
    updateActivityFeedNotificationStatus,
    updateUsersSelectedOrgIDs,
    updateOrganizationsphoneNumbers,
    updateOrganizationsUsers,
    updateFaceAuthenticationFiles,

} = counterSlice.actions

export default counterSlice.reducer
