import { ToasterTexts } from '../components/shared/constants';
import { ToasterType } from '../components/shared/toaster/toaster.model';
import Config from "../config";
import { AxiosInstance } from "../helpers/AxiosInstance";
import setToast from '../helpers/toaster.helper';
import { mapToAutoAlertKeyword, mapToAutoAlertRule } from '../models/auto-alert.model';
import { Contact, ContactType, ConversationModel, DeliveryStatus, mapToContact, mapToContactType, mapToConverstion, mapToGroup, mapToUser, OutLookResponse } from "../models/conversations/index";
import { mapToFeature } from '../models/features.model';
import { KafkaModel, mapToKafka } from '../models/kafka.model';
import { mapToOrganization } from "../models/organization.model";
import { mapToPhoneNumber, mapToProvider, PhoneNumberModel } from '../models/phone-number.model';
import { PlanMetrics, mapToPlanMetrics } from '../models/plan-metrics.model';
import { mapToProfile } from "../models/profile.model";
import { mapToReport } from '../models/reports.model';
import { mapToRole } from "../models/roles.model";
import { mapToSetting } from "../models/settings-model";
import { mapToTemplate, TemplateModel } from '../models/templates.models';
import { mapToPlanHistory } from '../models/transaction-history.model';
import {
    cleanHistory, clearHistory, clearToast, groupContactsDeletion, handlePushCampaignChatName, handlePushGroupChatName, hideToast, loadingKafkaOrg, loadingReports,
    loadingscheduleMessages, loadingSecureFiles, loadingSecureFilesLinks, LoadMsgLogsExports, pushConversations, pushMessageLogsDownloadReport, setKafka,
    setShowingAlert, syncingContacts, updateActivityFeedNotificationStatus, updateAlertNotification, updateBlockedContacts, updateContact, updateContacts, updateContactsInConversation, updateContactTypes,
    updateContactTypesInConversation, updateConversations, updateFeatures, updateGlobalAutomationRules, updateGroups, updateHistory, updateKafka, updateKeywords,
    updateLoadingAlertNotification, updateLoadingContacts, updateLoadingConversations, updateLoadingFeatures, updateLoadingGlobalAutomationRules, updateLoadingGroups,
    updateLoadingKeywords, updateLoadingOrganizations, updateLoadingPhoneNumbers, updateLoadingPlanes, updateLoadingPrivateAutomationRules,
    //  updateLoadingProfile,updatePlanMetrics, updateProfile, 
    updateLoadingProviders, updateLoadingTemplates, updateLoadingUsers, updateMassContactsDeletionInProgess, updateMassGroupsDeletionInProgess, updateMenuFlag,
    updateNewMessageRecived, updateOrganizations, updateOrgName, updatePhoneNumbers, updatePhoneNumbersGridData, updatePlanHistory, updatePlanMetrics, updatePlans,
    updatePrivateAutomationRules, updateProviders, updateReports, updateRoles, updateScheduleMessages, updateSecureFiles, updateSecureFilesLinks, updateSettings,
    updateTemplates, updateUnsubscribedContacts, updateUserAlertNotifaction, updateUserRoleId, updateUsers, updateDataLoading, updatePhonenumberSelectedOrgIDs, updateUsersSelectedOrgIDs, updateOrganizationsphoneNumbers, updateOrganizationsUsers,
    loadingFaceAuthenticationFiles,
    updateFaceAuthenticationFiles
} from '../reducers/reducer';
import { AutoAlertRulesService } from '../services/AutoAlertRulesService';
import { ContactService } from "../services/ContactService";
import { FeatureService } from '../services/FeatureService';
import { GroupContactService } from "../services/GroupContactService";
import { KafkaService } from '../services/KakfaServies';
import { MessageService } from '../services/MessageService';
import { OrganizationService } from "../services/OrganizationService";
import { PhoneNumberService } from '../services/PhonenumberService';
import { PlansService } from "../services/plans.service";
import { ReportService } from '../services/ReportService';
import { SettingsService } from "../services/SettingsService";
import { TemplateService } from '../services/TemplateService';
import { UserService } from "../services/UserService";
import { UtilityService } from '../services/UtilityService';
import { mapToAlertNotification } from './../models/alert.model';
import { mapToPlan } from './../models/plans.model';
import { PlanHistoryModel } from './../models/transaction-history.model';
import { AlertNotificationService } from './../services/AlertNotificationService';
import { mapToFaceAuthentication, mapToSecureFileNameWithLinksModel, mapToSecureFiles } from '../models/secure-file';
import { SecureFileService } from '../services/SecureFileService';
import { MessageLogSevice } from '../services/messageLogService';
import { mapToMessageLogExportModel } from '../models/msg.export.model';
import { mapToScheduledMessage } from '../models/conversations/schedule-messages.model';

export const SetUserRoleId = (dispatch: any, id: number) => {
    dispatch(updateUserRoleId(id))
}

export const LoadMessages = (dispatch: any) => {
    dispatch(updateLoadingConversations(true))
    AxiosInstance.get(`${Config.API_URL}/api/Messages`).then(
        async res => {
            let data = await res.data
            dispatch(updateConversations(data.map(mapToConverstion)))
        }
    )
}

export const LoadContactTypes = (dispatch: any): void => {
    AxiosInstance.get(`${Config.API_URL}/api/ContactTypes`).then(
        res => {
            let data = res.data.map(mapToContactType);
            dispatch(updateContactTypes(data))
            dispatch(updateContactTypesInConversation(data))
        }
    )
}

export const UpdateHistory = (dispatch: any, phoneNumber: string, history: ConversationModel[]) => {
    dispatch(updateHistory({ phoneNumber, history }))
}

export const LoadContacts = (dispatch: any, loadMessages: boolean = false) => {
    dispatch(updateLoadingContacts(true))
    dispatch(updateDataLoading(loadMessages))
    return AxiosInstance.get(`${Config.API_URL}/api/Contacts`).then(async res => {
        let data = await res.data?.map(mapToContact)
        dispatch(updateContacts(data))
        dispatch(updateDataLoading(false))
        dispatch(updateMassContactsDeletionInProgess(false))
        if (loadMessages) {
            LoadMessages(dispatch)
        }
        else {
            dispatch(updateContactTypesInConversation(data))
            dispatch(updateContactsInConversation(data))

        }
    }
    )
}

export const HandlePushConversation = (dispatch: any, data: ConversationModel[]) => {
    dispatch(pushConversations(data));
    data.forEach(conversation => {
        if (conversation.Message?.toLowerCase() === "stop" && !conversation.IsOutbound) {
            setToast(`${conversation.Contact?.Name || conversation.MaskedPhoneNumber} ${ToasterTexts.UNSUBSCRIBE_INFO}`, ToasterType.INFO)
        }
        if (conversation.Message?.toLowerCase() === "unstop" && !conversation.IsOutbound) {
            setToast(`${conversation.Contact?.Name || conversation.MaskedPhoneNumber} ${ToasterTexts.RESUBSCRIBE_INFO}`, ToasterType.INFO)
        }
        // if (conversation.DeliveryStatus === DeliveryStatus.SENT) {

        // }
        // if (conversation.DeliveryStatus === DeliveryStatus.FAILED ) {
        //     setToast(ToasterTexts.MESSAGE_SEND_FAIL.replace('<Name>', (conversation.Contact?.Name || conversation.MaskedPhoneNumber)), ToasterType.DANGER)
        // }
    })

}

export const LoadUsers = (dispatch: any) => {
    dispatch(updateLoadingUsers(true));
    UserService.getUsers().then(res => {
        dispatch(updateUsers(res.data.map(mapToUser)))
    })
}

export const LoadGroups = (dispatch: any) => {
    dispatch(updateLoadingGroups(true));
    GroupContactService.getGroups().then(async (res) => {
        let data = await res.data
        dispatch(updateGroups(data.map(mapToGroup)))
    })
}

export const LoadBlockedContacts = (dispatch: any) => {
    ContactService.getBlockedContacts().then((res) => {
        dispatch(updateBlockedContacts(res.data.map(mapToContact)))
    });
}

export const LoadUnSubcribedContacts = (dispatch: any) => {
    ContactService.getUnsubscribeContacts().then((res) => {
        dispatch(updateUnsubscribedContacts(res.data.map(mapToContact)))
    })
}

export const UpdatePlanMetrics = (dispatch: any, data: PlanMetrics) => {
    dispatch(updatePlanMetrics(mapToPlanMetrics(data)));
}

// export const LoadProfile = (dispatch: any) => {
//     dispatch(updateLoadingProfile(true))
//     return UserService.getCurrentUser(fromApi).then((res: any) => {
//         dispatch(updateProfile(mapToProfile(res.data)))
//         dispatch(updatePlanMetrics(mapToPlanMetrics(res.data.planTracker)))
//         return res;
//     }
//     )
// }

// export const UpdateProfile = (dispatch: any, profile: any) => {
//     dispatch(updateProfile(mapToProfile(profile)))
// }

export const LoadOrgName = (dispatch: any, id: string) => {
    OrganizationService.getOrganizationById(id).then((res) => {
        dispatch(updateOrgName(typeof res.data === "object" && res.data >= 0 ? res.data[0].name : res.data.name))
    })
}

export const UpdateContact = (dispatch: any, Contact: Contact) => {
    dispatch(updateContact(Contact))
}

export const UpdateContactTypes = (dispatch: any, data: ContactType[]): void => {
    dispatch(updateContactTypes(data))
    dispatch(updateContactTypesInConversation(data))
}

export const UpdateSettings = (dispatch: any, setting?: any): Promise<any> => {
    return SettingsService.updateSetting({ ...setting }).then(res => {
        dispatch(updateSettings(res.map(mapToSetting)))
    })
}

export const LoadSettings = (dispatch: any) => {
    return SettingsService.getAllSettings().then((res: any) => {
        dispatch(updateSettings(res.map(mapToSetting)))
    })
}

export const UpdateMenuFlag = (dispatch: any, flag: boolean) => {
    dispatch(updateMenuFlag(flag))
}


export const UpdateContactsMassActionDeletionStatus = (dispatch: any, flag: boolean) => {
    dispatch(updateMassContactsDeletionInProgess(flag))
}

export const UpdateContactsGroupsMassActionDeletionStatus = (dispatch: any, flag: boolean) => {
    dispatch(updateMassGroupsDeletionInProgess(flag))
}

export const ClearConversationHistory = (dispatch: any) => {
    dispatch(clearHistory())
}
export const CleanConversationHistory = (dispatch: any, number: string) => {
    dispatch(cleanHistory(number))
}

export const LoadOrganizations = (dispatch: any) => {
    dispatch(updateLoadingOrganizations(true))
    OrganizationService.getOrganizationsGridData().then(res => {
        dispatch(updateOrganizations(res.data.map(mapToOrganization)))
    })
}

export const LoadMessageLogsExport = (dispatch: any) => {
    MessageLogSevice.getExportedMesageLogs().then(res => {
        dispatch(LoadMsgLogsExports(res.data.map(mapToMessageLogExportModel)))
    })
}

export const ResetMessageLogsExportMsg = (dispatch: any) => {
    MessageLogSevice.getExportedMesageLogs().then(res => {
        dispatch(pushMessageLogsDownloadReport(""))
    })
}

export const getOrgSettings = (dispatch: any, id: string) => {
    SettingsService.getOrganizationSettings(id).then((res) => {
        dispatch(updateSettings(res.data.map(mapToSetting)))
    })
}

export const LoadFeatures = (dispatch: any) => {
    dispatch(updateLoadingFeatures(true))
    FeatureService.getAllFeature().then((res: any) => {
        dispatch(updateFeatures(res.data.map(mapToFeature)))
    })
}

export const LoadPhoneNumbers = (dispatch: any) => {
    dispatch(updateLoadingPhoneNumbers(true))
    return PhoneNumberService.getPhoneNumbers().then((res: any) => {
        dispatch(updatePhoneNumbers(res.data.map(mapToPhoneNumber).filter((x: PhoneNumberModel) => UtilityService.validPhoneNumber(x.PhoneNumber))))
        return res;
    })
}

export const LoadPhoneNumbersGridData = (dispatch: any) => {
    dispatch(updateLoadingPhoneNumbers(true))
    return PhoneNumberService.getPhoneNumbersGridData().then((res: any) => {
        dispatch(updatePhoneNumbersGridData(res.data.map(mapToPhoneNumber).filter((x: PhoneNumberModel) => UtilityService.validPhoneNumber(x.PhoneNumber))))
        return res;
    })
}


export const getRoles = (dispatch: any) => {
    UserService.getRoles().then((res: any) => {
        dispatch(updateRoles(res.data.map(mapToRole)))
    })
}
export const UpdateOrgSettings = (dispatch: any, id: string, setting: any, toasterMsg: string) => {
    SettingsService.UpdateOrganizationSettings(id, [{ ...setting }]).then(res => {
        dispatch(updateSettings(res.data.map(mapToSetting)))
        setToast(toasterMsg, ToasterType.SUCCESS)
    })
}

export const ClearToast = (dispatch: any, id: string) => {
    dispatch(hideToast({ id }))
    setTimeout(() =>
        dispatch(clearToast({ id }))
        , 500);
}

export const LoadPlans = (dispatch: any) => {
    dispatch(updateLoadingPlanes(true))
    return PlansService.getPlans().then((res: any) => {
        dispatch(updatePlans(res.data.map(mapToPlan)))
        return res;
    })
}

export const LoadProviders = (dispatch: any) => {
    dispatch(updateLoadingProviders(true))
    return PhoneNumberService.getProviders().then((res: any) => {
        dispatch(updateProviders(res.data.map(mapToProvider)))
        return res;
    })
}

export const MuteConversation = (dispatch: any, phonenumber: string, duration: number) => {
    return MessageService.muteConversation(phonenumber, duration).then((res: any) => {
        let data = res.map(mapToContactType);
        dispatch(updateContactTypes(data))
        dispatch(updateContactTypesInConversation(data))
        return res;
    })
}

export const LoadAlertNotifications = (dispatch: any) => {
    dispatch(updateLoadingAlertNotification(true))
    AlertNotificationService.getAllSystemAdminAlertNotifications().then(res =>
        dispatch(updateAlertNotification(res.data.map(mapToAlertNotification)))
    )
}

export const LoadUserAlertNotifications = (dispatch: any) => {
    AlertNotificationService.getAllUserAlertNotifications().then(res =>
        dispatch(updateUserAlertNotifaction(res.data.map(mapToAlertNotification)))
    )
}

export const SetAlertFlag = (dispatch: any, count: number) => {
    dispatch(setShowingAlert(count))
}

export const loadAutoAlertKeywords = (dispatch: any) => {
    dispatch(updateLoadingKeywords(true))
    AutoAlertRulesService.getAutoAlertKeywords().then(res =>
        dispatch(updateKeywords(res.data.map(mapToAutoAlertKeyword)))
    )
}

export const LoadTemplates = (dispatch: any) => {
    dispatch(updateLoadingTemplates(true));
    TemplateService.getTemplates().then(res => {
        dispatch(updateTemplates({ data: res.data.map(mapToTemplate), refresh: true }))
    })
}

export const LoadKafkaOrg = (dispatch: any) => {
    dispatch(loadingKafkaOrg(true))
    KafkaService.getKafkaOrganiztons().then(res => {
        dispatch(setKafka(res.data?.map(mapToKafka)))
    })
}
export const syncContacts = (dispatch: any, data: OutLookResponse) => {
    dispatch(syncingContacts(data))
}

export const UpdateKafkaOrg = (dispatch: any, data: KafkaModel) => {
    dispatch(updateKafka(data))
}

export const loadPrivateAutomationRules = (dispatch: any) => {
    dispatch(updateLoadingPrivateAutomationRules(true))
    return AutoAlertRulesService.getAutoAlertPrivateRules().then(res =>
        dispatch(updatePrivateAutomationRules(res.data.map(mapToAutoAlertRule)))
    )
}
export const loadGlobalAutomationRules = (dispatch: any) => {
    dispatch(updateLoadingGlobalAutomationRules(true))
    return AutoAlertRulesService.getAutoAlertGlobalRules().then(res =>
        dispatch(updateGlobalAutomationRules(res.data.map(mapToAutoAlertRule)))
    )
}

export const LoadReports = (dispatch: any, page: number, pageSize: number) => {
    dispatch(loadingReports(true))
    ReportService.getReports(page, pageSize).then(res => {
        dispatch(updateReports(res.data.map(mapToReport)))
    })
}


export const LoadPlanHistory = (dispatch: any, phoneID: string) => {
    PhoneNumberService.RechargeHistory(phoneID).then((res) => {
        dispatch(updatePlanHistory(res.data.map(mapToPlanHistory)?.filter((x: PlanHistoryModel) => x)))
    })
}
export const LoadGroupContactsDeletion = (dispatch: any, data: any) => {
    dispatch(groupContactsDeletion(data))
}

export const LoadSecureFiles = (dispatch: any, id: any) => {
    dispatch(loadingSecureFiles(true)
    )
    SecureFileService.getAllSecureFiles(id).then(res => {
        dispatch(updateSecureFiles(res.data.map(mapToSecureFiles)))
    })
}

export const LoadFaceAuthenticationFiles = (dispatch: any, organizationId: any) => {
    dispatch(loadingFaceAuthenticationFiles(true)
    )
    SecureFileService.getFaceStatusFiles(organizationId).then(res => {
        dispatch(updateFaceAuthenticationFiles(res.data.map(mapToFaceAuthentication)))
    })
}

export const LoadSecureFileLinks = (dispatch: any) => {
    let id = { "user_id": UserService.getCurrentUserId() }
    dispatch(loadingSecureFilesLinks(true))
    SecureFileService.getAllSecureFileNameAndLinks(id).then(res => {
        dispatch(updateSecureFilesLinks(res.data.map(mapToSecureFileNameWithLinksModel)))
    })
}

export const UpdateNewMessageRecived = (dispatch: any, Message: ConversationModel[]) => {
    dispatch(updateNewMessageRecived({ Message })
    )
}

export const PushMessageLogsDownloadReport = (dispatch: any, msg: string) => {
    dispatch(pushMessageLogsDownloadReport(msg)
    )
}
export const HandlePushGroupChatName = (dispatch: any, id: string, name: string) => {
    dispatch(handlePushGroupChatName({ id, name }))
}

export const HandlePushCampaignChatName = (dispatch: any, id: string, name: string) => {
    dispatch(handlePushCampaignChatName({ id, name }))
}

export const LoadScheduleMessages = (dispatch: any, AllContacts, groups) => {
    dispatch(loadingscheduleMessages(true))
    MessageService.getScheduleMessage(UserService.getUserPhoneNumberId())
        .then((res) => {
            dispatch(updateScheduleMessages(res.data.map(x => mapToScheduledMessage(x, AllContacts, groups))))
        })
        .catch((err) => {
        })
}

export const LoadActivityFeedNotificationStatus = (dispatch: any) => {
    SettingsService.getActivityFeedNotificationStatus(JSON.parse(localStorage.getItem("activityInfo")!)?.userId).then(res => {
        dispatch(updateActivityFeedNotificationStatus(res))
    })
}

export const UpdatePhonenumbersSelectedOrgIDs = (dispatch: any, orgids: string[]) => {
    dispatch(updatePhonenumberSelectedOrgIDs(orgids))
}

export const UpdateUsersSelectedOrgIDs = (dispatch: any, orgids: string[]) => {
    dispatch(updateUsersSelectedOrgIDs(orgids))
}

export const UpdateOrganizationsphoneNumbers = (dispatch: any, data: PhoneNumberModel[]) => {
    dispatch(updateOrganizationsphoneNumbers(data))
}

export const UpdateOrganizationsUsers = (dispatch: any, data: PhoneNumberModel[]) => {
    dispatch(updateOrganizationsUsers(data))
}



